<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>{{
        student.firstname + " " + student.lastname
      }}</v-toolbar-title>
    </template>

    <v-container>
      <v-row>
        <v-card width="100%" elevation="0" class="pa-2 text-center rounded-0">
          <v-list-item-avatar size="100">
            <v-img :src="student.photo"></v-img>
          </v-list-item-avatar>

          <h2>{{ student.firstname + " " + student.lastname }}</h2>
          <p>{{ student.email }}</p>
        </v-card>
      </v-row>
      <v-divider></v-divider>
      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-account </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="student.fullname"></v-list-item-title>
            <v-list-item-subtitle>Nama Lengkap</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-home-city </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="student.classname"></v-list-item-title>
            <v-list-item-subtitle>Kelas</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-phone </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="student.phone_no"></v-list-item-title>
            <v-list-item-subtitle>No. Telp</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-email </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="student.email"></v-list-item-title>
            <v-list-item-subtitle>Email</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-map-marker </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="student.address"></v-list-item-title>
            <v-list-item-subtitle>Alamat</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "MyChildInfo",
  components: { DefaultLayout },

  data: () => ({
    student: {},
  }),

  methods: {
    async getInfo() {
      this.$store.state.showPageOverlay = true;
      await this.$axios
        .get("student/info", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.$store.state.showPageOverlay = false;
          if (res.data.status == "success") {
            this.student = res.data.data;
          } else {
            this.student = [];
          }
        });
    },
  },

  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push("/login");
    }
  },

  mounted() {
    this.getInfo();
    document.title = "Info Siswa";
  },
};
</script>